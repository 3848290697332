const SPINA_API_KEY = import.meta.env.VITE_SPINA_API_KEY;
const authorizationToken = `Token ${SPINA_API_KEY}`;

export default async function getSinglePageInfo(view_template: string) {
  const fullEndpoint = `/api/pages/template/${view_template}.json`;

  try {
    const response = await fetch(fullEndpoint, {
      headers: {
        Authorization: authorizationToken,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data?.data?.attributes?.content;
  } catch (error: any) {
    console.error('Error fetching page:', error.message);
    return null;
  }
}
